import React from 'react';
import './asin-page-view-screen.scss'

interface apmcDateProps {
    selectedApmcName: string | null,
    currentDate: string | null,
}

export default function AsinDateComponent({selectedApmcName, currentDate}: apmcDateProps) {
    return (
        <>
            <table className={'asin-date-component-table-style'}>
                <tbody>
                <tr>
                    <th className='table-style'>Date</th>
                    <td className='table-style'>{currentDate}</td>
                </tr>
                <tr>
                    <th className='table-style'>APMC</th>
                    <td className='table-style'>{selectedApmcName}</td>
                </tr>
                </tbody>
            </table>
        </>
    )
}
