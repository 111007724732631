import {CognitoAuth, CognitoAuthOptions} from 'amazon-cognito-auth-js';
import {getStage} from "src/utils/apiUtils";
import jwtDecode from "jwt-decode";
import {isUserAuthorized} from "src/utils/requests";
const authConfig = require('./AuthConfig.json');

const commonCognitoConfig = (stage: any) => {
    return authConfig.auth_stages[stage]
};

export const getCognitoConfig = (): CognitoAuthOptions => {
    const stage = getStage();
    const cognitoConfig = commonCognitoConfig(stage);
    const redirectURI = location.origin;
    return {
        ClientId: cognitoConfig.clientId,
        AppWebDomain: cognitoConfig.domain,
        TokenScopesArray: cognitoConfig.tokenScopesArray,
        RedirectUriSignIn: redirectURI,
        RedirectUriSignOut: redirectURI,
        UserPoolId: cognitoConfig.userPoolId,
        IdentityProvider: cognitoConfig.identityProvider,
    };
};

export const auth = new CognitoAuth(getCognitoConfig())

export const getCognitoSession = () => {
    // Create and initialize CognitoAuth
    const authConfig = getCognitoConfig();
    const auth = new CognitoAuth(authConfig);
    auth.useCodeGrantFlow();

    let isRefreshed = false;
    const reloadCount = localStorage.getItem("ReloadCount");

    auth.userhandler = {
        onFailure: () => {
            console.log('Cognito Authentication failed. Please look into the Cognito User Pool Configuration');
            removeQueryFromLocation();
            auth.clearCachedTokensScopes();
            localStorage.removeItem("ReloadCount");
        },
        onSuccess: (result) => {
            console.log('Authentication has been done successfully using AWS Cognito');
            if(reloadCount == null || isRefreshed) {
                window.location.reload();
                // This will enable refresh on every refresh of creds
                isRefreshed = false;
                // This will enable the initial refresh after authentication
                localStorage.setItem("ReloadCount", String(1));
            }
            removeQueryFromLocation();
        },
    }
    let user = auth.getCachedSession();
    const href = window.location.href;
    const session = auth.getSignInUserSession();
    if (session.isValid()) {
        console.log('Cognito Session is valid. Using current JWT token for user authentication')
        isUserAuthorized();
    } else if (href.indexOf('?') > 0) {
        console.log('Parse the cognito session response');
        auth.parseCognitoWebResponse(href);
    } else {
        console.log('Cognito Session is invalid. Generating new session for the user.');
        auth.refreshSession(user.getRefreshToken().getToken());
        isRefreshed = true;
        console.log("New Credentials generated.")
        auth.getSession();
    }
    return session;
}

export function retrieveUserInfo(token: string) {
    const response: any = jwtDecode(token);
    const alias = response.identities[0].userId;
    return alias;
}

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable. Sometimes
// the SDK will report weird message because of using old grant code.
function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?')[0];
        window.history.replaceState(undefined, 'Field Price Website', newHref);
    }
}
