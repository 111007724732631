import {GAMMA, PROD, STAGES} from "src/utils/constants";

const authConfig = require('../authentication/AuthConfig');

export const getStage = () => {
    const hostName = window.location.hostname;
    const root = hostName.split(".")[0];

    if(hostName.includes(GAMMA)) {
        return "gamma";
    } else if(hostName.includes(PROD)) {
        return "prod";
    } else if (STAGES.includes(root)) {
        return root;
    }
    return "beta";
};

const stage: any = getStage();
const authStageData = authConfig.auth_stages[stage];

//Change the stage in below URL to beta for local testing.
export const getApiGatewayBaseURL = `https://${authStageData.apiGatewayEndpointBaseIdentifier}.execute-api.${authStageData.region}.amazonaws.com/${stage}`
