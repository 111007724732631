// @ts-nocheck

import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import './logger';

import '@amzn/katal-components/styles.css';
import './index.scss';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
    <AppInitWrapper>
      <React.Suspense fallback={<KatSpinner size='large' />}>
        <App />
      </React.Suspense>
    </AppInitWrapper>,
  document.getElementById('root')
);

if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
        navigator.serviceWorker.register("/service-worker.js").then(
            function (registration) {
                // registration successful :)
                console.log(
                    "ServiceWorker registration successful with scope: ",
                    registration.scope
                );
            },
            function (err) {
                // registration failed :(
                console.log("ServiceWorker registration failed: ", err);
            }
        );
    });
}
