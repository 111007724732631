import React, {useCallback} from 'react';
import Button from "@amzn/meridian/button"
import Checkbox from "@amzn/meridian/checkbox"
import Divider from "@amzn/meridian/divider"
import SearchField from "@amzn/meridian/search-field"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import './asin-list-view-screen.scss';
import Navbar from "../../NavBar/Navbar";
import { useNavigate } from "react-router-dom";
import {convertCurrentDateToLocalDate, convertPreviousDateToLocalDate} from "src/commons/utils";
import {doPostCall} from "src/utils/requests";
import {CURRENT_DATE_KEY, PREFERRED_ASIN_KEY, SELECTED_APMC_NAME_KEY} from "src/utils/constants";
import Loader from "@amzn/meridian/loader"

export interface preferredAsin {
    asin: string,
    title: string,
    disassembledAsinUom: string,
    qualitySpecs: string,
    packSize: number,
    packType: string,
    isBuyingPlanAsin: boolean,
}

export interface preferredAsinWithNumberOfTrades {
    asin: string,
    title: string,
    uom: string,
    qualitySpecs: string,
    packSize: number,
    packType: string,
    isBuyingPlan: boolean,
    numberOfTrades: number
}

export default function AsinListViewScreen() {
    const selectedApmcName = localStorage.getItem(SELECTED_APMC_NAME_KEY);
    const currentDate = localStorage.getItem(CURRENT_DATE_KEY);
    const [preferredAsinList, setPreferredAsinList] = React.useState<preferredAsin[]>([]);
    const [numberOfTradesMap, setNumberOfTradesMap] = React.useState([]);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [loadingData, setLoadingData] = React.useState(true)
    const [currDayBuyingPlanPresent, setCurrDayBuyingPlanPresent] = React.useState(true);
    const [prevDayBuyingPlanPresent, setPrevDayBuyingPlanPresent] = React.useState(false);
    let preferredAsinWithNumberOfTradesList: preferredAsinWithNumberOfTrades[] = [];

    React.useEffect(() => {
        const requestData = {
            'apmcName': selectedApmcName,
            'date': convertCurrentDateToLocalDate()
        };
        const requestDataPreviousDay = {
            'apmcName': selectedApmcName,
            'date': convertPreviousDateToLocalDate()
        };

        doPostCall(requestData, '/getPreferredAsins').then((response) => {
            if (response && response["asins"] && isBuyingPlanPresent(response["asins"])) {
                setPreferredAsinList(response.asins);
                setLoadingData(false)
            } else {
                setCurrDayBuyingPlanPresent(false);
                doPostCall(requestDataPreviousDay, '/getPreferredAsins').then((response) => {
                    setLoadingData(false);
                    if(response && response["asins"]) {
                        setPreferredAsinList(response.asins);
                        if(isBuyingPlanPresent(response.asins)) {
                            setPrevDayBuyingPlanPresent(true);
                        } else {
                            setPrevDayBuyingPlanPresent(false);
                        }
                    } else {
                        setPreferredAsinList([]);
                    }
                });
            }
        });

        doPostCall(requestData, '/getTradesCount').then((response) => {
            if (response && response["asinToTradeCountMap"]) {
                setNumberOfTradesMap(response["asinToTradeCountMap"]);
            } else {
                setNumberOfTradesMap(new Map() as any);
            }
        })
    }, []);

    const isBuyingPlanPresent = (preferredAsins: preferredAsin[]) => {
        for(let preferredAsin of preferredAsins) {
            if(preferredAsin.isBuyingPlanAsin) {
                return true;
            }
        }
        return false
    }

    const [filteredPreferredAsinList, setFilteredPreferredAsinList] = React.useState(preferredAsinWithNumberOfTradesList);
    const [queriedPreferredAsinList, setQueriedPreferredAsinList] = React.useState(preferredAsinWithNumberOfTradesList);

    const [query, setQuery] = React.useState("");
    const [checkBuyingPlanFilter, setCheckBuyingPlanFilter] = React.useState(true)
    const [checkCompletedAsinFilter, setCheckCompletedAsinFilter] = React.useState(false)

    preferredAsinList.forEach((preferredAsin) => {
        const numberOfTrades = numberOfTradesMap[preferredAsin.asin] ? numberOfTradesMap[preferredAsin.asin] : 0;
        preferredAsinWithNumberOfTradesList.push(
            {
                "asin": preferredAsin.asin,
                "title": preferredAsin.title,
                "qualitySpecs": preferredAsin.qualitySpecs,
                "packSize": preferredAsin.packSize,
                "packType": preferredAsin.packType,
                "isBuyingPlan": preferredAsin.isBuyingPlanAsin,
                "uom": preferredAsin.disassembledAsinUom,
                "numberOfTrades": numberOfTrades
            },
        )
    });

    const applyFilters = () => {
        let filteredPreferredAsinList = preferredAsinWithNumberOfTradesList;
        if(checkBuyingPlanFilter && checkCompletedAsinFilter) {
            filteredPreferredAsinList = filteredPreferredAsinList.filter(asin => (asin.isBuyingPlan && asin.numberOfTrades > 0));
        }
        if (checkBuyingPlanFilter && !checkCompletedAsinFilter) {
            filteredPreferredAsinList = filteredPreferredAsinList.filter(asin => (asin.isBuyingPlan && asin.numberOfTrades == 0));
        }
        if (checkCompletedAsinFilter) {
            filteredPreferredAsinList = filteredPreferredAsinList.filter(asin => asin.numberOfTrades > 0);
        }
        setFilteredPreferredAsinList(filteredPreferredAsinList);
    }

    const submitSearch = (query: string) => {
        var queriedPreferredAsinList = filteredPreferredAsinList
            .filter(asin => asin.asin.toLowerCase().includes(query.toLowerCase())
                || asin.title.toLowerCase().includes(query.toLowerCase()));

        setQueriedPreferredAsinList(queriedPreferredAsinList);
    }

    React.useEffect(() => {
        submitSearch(query);
    }, [query, filteredPreferredAsinList]);

    React.useEffect(() => {
        applyFilters();
    }, [checkBuyingPlanFilter, checkCompletedAsinFilter]);

    if(preferredAsinWithNumberOfTradesList.length!=0 && numberOfTradesMap.length!=0 && !refreshCount){
        applyFilters();
        setRefreshCount(1);
    }

    let navigate = useNavigate();
    const onClickPreferredAsinAction = (selectedPreferredAsin: preferredAsinWithNumberOfTrades) => {
        localStorage.setItem(PREFERRED_ASIN_KEY, JSON.stringify(selectedPreferredAsin))
        navigate(`/asinView`);
    }

    return (
        <>
            <Navbar/>
            <div className={"page-content"}>
                <div className={"page-header-wrapper"}>
                    <div className={"date-label"}>
                        {`Date: ${currentDate}`}
                    </div>
                    <div className={"apmc-label"}>
                        {`APMC: ${selectedApmcName}`}
                    </div>
                </div>
                <div className={"filter-items-bar"}>
                    <text className={"filter-item-header"}>Filter by:</text>
                    <div className={"filter-item-category-content"}>
                        <text className={"filter-item-category"}>
                            <Checkbox
                                checked={checkBuyingPlanFilter}
                                onChange={setCheckBuyingPlanFilter}>
                                Buying plan
                            </Checkbox>
                        </text>
                        <text className={"filter-item-category"}>
                            <Checkbox
                                checked={checkCompletedAsinFilter}
                                onChange={setCheckCompletedAsinFilter}>
                                Completed Asin
                            </Checkbox>
                        </text>
                    </div>
                </div>

                {
                    checkBuyingPlanFilter && !currDayBuyingPlanPresent && prevDayBuyingPlanPresent &&
                    <div className={'no-curr-day-buying-plan-text'}>
                        <text>
                            Displaying yesterday's buying plan since no buying plan was found for today.
                        </text>
                    </div>
                }

                <Divider />
                <div className={"asin-table-wrapper"}>
                    <Table className={"asin-list-table-cell"} headerRows={1}>
                        <TableRow>
                            <TableCell width="32%" alignmentHorizontal={"left"}>ASIN</TableCell>
                            <TableCell width="32%" alignmentHorizontal={"left"}>Item Name</TableCell>
                            <TableCell width="15%" alignmentHorizontal={"center"}>No. of Trades</TableCell>
                            <TableCell alignmentHorizontal={"center"}>Action</TableCell>
                        </TableRow>
                        {
                            queriedPreferredAsinList.map(preferredAsin => (
                                    <TableRow highlightOnHover={true} key={preferredAsin.asin}>
                                        <TableCell alignmentHorizontal={"left"}>{preferredAsin.asin}</TableCell>
                                        <TableCell alignmentHorizontal={"center"}>{preferredAsin.title}</TableCell>
                                        <TableCell alignmentHorizontal={"center"}>{preferredAsin.numberOfTrades}</TableCell>
                                        <TableCell alignmentHorizontal={"center"}>
                                            <Button
                                                onClick={() => onClickPreferredAsinAction(preferredAsin)}
                                                size="small">
                                                Add/Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </Table>
                </div>

                {(loadingData) &&
                    <div className={'loader-wrapper'}>
                        <Loader
                            type="circular"
                            size="large">
                        </Loader>
                    </div>

                }

                {(!currDayBuyingPlanPresent) && (!prevDayBuyingPlanPresent) && (checkBuyingPlanFilter) &&
                    <div className={'no-buying-plan-text'}>
                        No Buying plan configured for today.
                        <br/>
                        Please contact the Vendor Manager.
                    </div>
                }

                <div className={"bottom-search-bar"}>
                    <SearchField
                        value={query}
                        onChange={setQuery}
                        onSubmit={() => submitSearch(query)}
                    />
                </div>
            </div>
        </>
    )

}
