import React from 'react';
import AsinDateComponent from "src/components/Pages/AsinPageViewScreen/AsinDateComponent";
import TradeDetailComponent from "src/components/Pages/AsinPageViewScreen/TradeDetailComponent";
import Navbar from "src/components/NavBar/Navbar";
import './asin-page-view-screen.scss'
import {CURRENT_DATE_KEY, PREFERRED_ASIN_KEY, SELECTED_APMC_NAME_KEY} from "src/utils/constants";

export default function AsinPageViewScreen() {

    const selectedApmcName = localStorage.getItem(SELECTED_APMC_NAME_KEY);
    const currentDate = localStorage.getItem(CURRENT_DATE_KEY);
    let storedSelectedPreferredAsin = localStorage.getItem(PREFERRED_ASIN_KEY);
    let selectedPreferredAsin;
    if(storedSelectedPreferredAsin !== null) {
        selectedPreferredAsin = JSON.parse(storedSelectedPreferredAsin);
    }

    return (
        <div className={'asin-view-page-content'}>
            <Navbar/>
            <AsinDateComponent
                selectedApmcName={selectedApmcName}
                currentDate={currentDate} />
            <div className='desired-quality-spec'>{selectedPreferredAsin.title}</div>
            <TradeDetailComponent
                selectedApmcName={selectedApmcName}
                currentDate={currentDate}
                buyingAsinItem={selectedPreferredAsin} />
        </div>
    )
}
