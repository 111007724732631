import React from 'react';
import APMCDropdown from './APMCDropdown';
import {KatButton} from '@amzn/katal-react';
import './apmc-selection-screen.scss';
import { useNavigate } from "react-router-dom";
import {doGetCall, isUserAuthorized} from "src/utils/requests";
 
export interface APMC {
    apmcId: string;
    apmcName: string;
}

interface Props {userAlias: String}
 
export default function APMCSelection({userAlias}: Props) {

    const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const userName = userAlias;

    const [selectedApmc, setSelectedApmc] = React.useState("");
    const [apmcList, setApmcList] = React.useState<APMC[]>([]);

    React.useEffect(() => {
        doGetCall('/getAllAPMCs').then((response) => {
            setApmcList(response.apmcList);
        })
    }, [])

    const onApmcSelection = (apmc: string) => {
        setSelectedApmc(apmc);
    }

    let navigate = useNavigate();
    const onConfirmClick = () => {
        const date = new Date();
        const currentDate = `${date.getDate()}-${monthName[date.getMonth()]}-${date.getFullYear()}`;
        localStorage.setItem("selectedApmcName", selectedApmc)
        localStorage.setItem("currentDate", currentDate)
        isUserAuthorized();
        navigate(`/asinListView`);
    }

    return (
        <div>
            <div className="name-display-box">
                <text className={"welcome-text-style"}>{`Welcome, ${userName}`}</text>
            </div>
            <div className="select-apmc-label-text-style">
                <text>Select APMC below</text>
            </div>
            <APMCDropdown
                apmcList={apmcList}
                onApmcSelection={onApmcSelection}
            />
            <div className={'confirm-button-wrapper'}>
                <KatButton
                    id={'primary_button'}
                    variant="primary"
                    label={"Confirm"}
                    className={'selection-screen-button-style'}
                    onClick={onConfirmClick}
                    disabled={!selectedApmc}
                />
            </div>
        </div>
    );
}
