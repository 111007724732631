// @ts-nocheck
import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import APMCSelection from './Pages/APMCSelectionScreen/APMCSelection';
import AsinListViewScreen from './Pages/AsinListViewScreen/AsinListView';
import AsinPageViewScreen from './Pages/AsinPageViewScreen/AsinPageView';
import AuthContext from '../context/AuthContext';
import {getCognitoSession, retrieveUserInfo} from "src/authentication/AuthGenerator";

let userAlias: any;

function App() {
    function routeBuilder() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<APMCSelection userAlias={userAlias}/>}/>
                        <Route path="/asinListView" element={<AsinListViewScreen/>} />
                        <Route path="/asinView" element={<AsinPageViewScreen/>} />
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }

    const session = getCognitoSession();
    userAlias = retrieveUserInfo(session.getIdToken().getJwtToken());
    return session.isValid() ? <AuthContext.Provider value={session.getIdToken().getJwtToken()}>{routeBuilder()} </AuthContext.Provider> : (
            <div >
                <h2>Authenticating...</h2>           
            </div>
        );
}

export default App;
