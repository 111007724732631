import React, {useState, useEffect, useCallback} from 'react';
import './asin-page-view-screen.scss'
import Button from "@amzn/meridian/button";
import {convertCurrentDateToLocalDate, SAVE_TRADE_CUTOFF_TIME} from 'src/commons/utils';
import Input from "@amzn/meridian/input";
import {preferredAsinWithNumberOfTrades} from "src/components/Pages/AsinListViewScreen/AsinListView";
import {getCognitoSession, retrieveUserInfo} from "src/authentication/AuthGenerator";
import {useNavigate} from "react-router-dom";
import {doPostCall, isUserAuthorized} from "src/utils/requests";
const uuid = require('uuidv4').default;
import { KatDropdown } from '@amzn/katal-react';
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Row from "@amzn/meridian/row"
import Alert from "@amzn/meridian/alert"
import {AlertType} from "@amzn/meridian/alert/alert";

interface tradeDetailProps {
    selectedApmcName: string | null,
    currentDate: string | null,
    buyingAsinItem: preferredAsinWithNumberOfTrades,
}

interface tradeDetail {
    tradeId: string,
    apmcName: string | null,
    asin: string,
    date: string,
    asinTitle: string,
    unitOfMeasure: string,
    desiredQualitySpec: string,
    actualQualitySpec: string,
    packType: string,
    packSize: number,
    volume: number,
    price: {
        unit: string,
        value: number
    },
    userLogin: string
}

export default function TradeDetailComponent({selectedApmcName, currentDate, buyingAsinItem}: tradeDetailProps) {

    const uomGrams = "g";
    const uomKiloGrams = "kg";
    const [packSize, setPackSize] = useState(1);
    const [packType, setPackType] = useState("Bag");
    const [volume, setVolume] = useState(0);
    const [price, setPrice] = useState(0);
    const [actualQualitySpec, setActualQualitySpec] = useState("");
    const [getTradesResponse, setGetTradesResponse] = useState<tradeDetail>();
    const [refreshCount, setRefreshCount] = useState(0);
    const [refreshCountWhenNoTrades, setRefreshCountWhenNoTrades] = useState(0);
    const [tradeIdString, setTradeIdString] = useState(uuid());
    const [openValidationErrorPrompt, setOpenValidationErrorPrompt] = useState(false)
    const [validationErrorPromptContent, setValidationErrorPromptContent] = useState<string[]>([])
    const [openSaveTradePrompt, setOpenSaveTradePrompt] = useState(false)
    const [saveTradePromptContent, setSaveTradePromptContent] = useState<string[]>([])
    const onClickButton = useCallback(() => setOpenValidationErrorPrompt(false), [])
    const onClickFooterButton = useCallback(() => setOpenSaveTradePrompt(false), [])
    const [savingTrade, setSavingTrade] = useState(false);
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save")
    const onValidationErrorClose = useCallback(() => setOpenValidationErrorPrompt(false), [])
    const onSaveTradeClose = useCallback(() => setOpenSaveTradePrompt(false), [])
    const [isAlert, setIsAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState<AlertType>("error");

    const packTypes = [
        {"name":"Bag", "value":"Bag"},
        {"name":"Box", "value":"Box"},
        {"name":"Loose", "value":"Loose"},
        {"name":"Crates", "value":"Crates"}
    ];

    useEffect(() => {
        const requestData = {
            'apmcName': selectedApmcName,
            'date': convertCurrentDateToLocalDate(),
            'asin': buyingAsinItem.asin
        };
        doPostCall(requestData, '/getTrades').then((response) => {
            if(response && response["tradeList"]) {
                setGetTradesResponse(response["tradeList"][0]);
            } else {
                setGetTradesResponse({} as tradeDetail);
            }
        })

        if(buyingAsinItem.numberOfTrades == 0) {
            doPostCall(requestData, '/getPreferredAsins').then((response) => {
                setPackSize(1);
                setPackType("Bag");
                if(response && response["asins"].length > 0) {
                    if(response["asins"][0].packSize) setPackSize(response["asins"][0].packSize)
                    if(response["asins"][0].packType) setPackType(response["asins"][0].packType)
                }
            })
        }
    }, []);

    if(getTradesResponse != undefined && !refreshCount) {
        setPackSize(getTradesResponse.packSize);
        setPackType(getTradesResponse.packType);
        setVolume(getTradesResponse.volume);
        setPrice(getTradesResponse.price["value"]);
        setActualQualitySpec(getTradesResponse.actualQualitySpec);
        setTradeIdString(getTradesResponse.tradeId);
        setRefreshCount(1);
    }

    const onPackTypeSelection = (packType: string) => {
        setPackType(packType);
    }

    let navigate = useNavigate();

    function saveTradeHandler() {
        let validationMessagesList = [];
        let saveTradeSummaryList = [];
        let inputVolume = Number(volume);
        let inputPrice = Number(price);
        let inputPackSize = Number(packSize);

        const currentDate = new Date();
        if(currentDate.getHours() > SAVE_TRADE_CUTOFF_TIME) {
            validationMessagesList.push("Trades cannot be saved after 9 A.M.");
            setValidationErrorPromptContent(validationMessagesList)
            setOpenValidationErrorPrompt(true)
            return;
        }

        if(inputVolume <= 0) {
            validationMessagesList.push("Volume should be greater than 0");
        }
        if(inputPrice <= 0) {
            validationMessagesList.push("Price should be greater than 0");
        }
        if(inputPrice > 10000) {
            validationMessagesList.push("Price should be less than 10000");
        }
        if(inputPackSize <= 0) {
            validationMessagesList.push("PackSize should be greater than 0");
        }

        if(validationMessagesList.length > 0) {
            setValidationErrorPromptContent(validationMessagesList);
            setOpenValidationErrorPrompt(true)
        } else {
            saveTradeSummaryList.push(`Volume - ${volume}`)
            saveTradeSummaryList.push(`Price - ${price}`)

            if(inputPackSize !== buyingAsinItem.packSize) {
                saveTradeSummaryList.push(`Pack Size: ${inputPackSize}`)
            }
            if(packType !== buyingAsinItem.packType) {
                saveTradeSummaryList.push(`Pack Type: ${packType}`)
            }
            if(actualQualitySpec !== "") {
                saveTradeSummaryList.push(`Quality Specs: ${actualQualitySpec}`)
            }
            setSaveTradePromptContent(saveTradeSummaryList);
            setOpenSaveTradePrompt(true)
        }
    }

    //This button will make POST call to SaveTrade API
    const onClickSaveTradeButton = () => {
        setSaveBtnLabel("Saving...");
        setSavingTrade(true);
        const tradeDetailObject: tradeDetail = {
            tradeId: tradeIdString,
            apmcName: selectedApmcName,
            asin: buyingAsinItem.asin,
            date: convertCurrentDateToLocalDate(),
            asinTitle: buyingAsinItem.title,
            unitOfMeasure: buyingAsinItem.uom,
            desiredQualitySpec: buyingAsinItem.qualitySpecs,
            actualQualitySpec: actualQualitySpec,
            packType: packType,
            packSize: Number(packSize),
            volume: Number(volume),
            price: {
                unit: "INR",
                value: Number(price)
            },
            userLogin: retrieveUserInfo(getCognitoSession().getIdToken().getJwtToken())
        };
        const data = {
            tradeDetail: tradeDetailObject
        }
        // checks if user is Authorised before performing save operation
        isUserAuthorized();
        doPostCall(data, '/saveTrade').then((response) => {
            console.log("Trade " + data + "saved Successfully");
            setOpenSaveTradePrompt(false);
            navigate('/asinListView', {state: {selectedApmcName, currentDate}});
        })
        setOpenSaveTradePrompt(false);
        if(buyingAsinItem.numberOfTrades == 0) {
            setAlertMessage("Trade Saved Successfully");
        } else {
            setAlertMessage("Trade Updated Successfully");
        }
        setAlertType("success");
        setIsAlert(true);
    }

    const validationErrorMessages = validationErrorPromptContent.map(
        (validationError) => {return(<text>{validationError}</text>)})

    const saveTradeSummary = saveTradePromptContent.map(
        (tradeSummary) => {return(<text>{tradeSummary}</text>)})

    const asinUom = buyingAsinItem.uom === uomGrams ? uomKiloGrams : buyingAsinItem.uom;

    const renderAlertMessage = () => {
        if (isAlert) {
            return(<Alert size="xlarge" type={alertType} onClose={() => setIsAlert(false)} width-left="5px">
                    {alertMessage}
            </Alert>);
        }
    }

    return (
        <>
            {renderAlertMessage()}
        <table className='table-wrapper-style '>
            <tbody>
                <tr>
                    <th className='table-style'>Quality Specification</th>
                    <td className='table-style'>{buyingAsinItem.qualitySpecs}</td>
                </tr>
                <tr>
                    <th className='table-style'>UOM</th>
                    <td className='table-style'>{asinUom}</td>
                </tr>
                <tr>
                    <th className='table-style'>
                        <div className={'cell-text-style'}>
                            <text>PackType</text>
                            <text className={'super-script-style'}>1</text>
                        </div>
                    </th>
                    <td className='table-style'>
                        <KatDropdown
                            value={packType}
                            options ={packTypes}
                            placeholder="Select Pack Type"
                            onChange={e=>onPackTypeSelection(e.detail.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <th className='table-style'>
                        <div className={'cell-text-style'}>
                            <text>PackSize</text>
                            <text className={'super-script-style'}>1</text>
                        </div>
                    </th>
                    <td className='table-style'>
                    <Input
                        id="pack-size-input"
                        value={packSize}
                        onChange={setPackSize}
                        type="number"
                        placeholder="Pack Size"/>
                    </td>
                </tr>
                <tr>
                    <th className='table-style'>Actual Quality Spec</th>
                    <td className='table-style'>
                    <Input
                        id="pack-size-input"
                        value={actualQualitySpec}
                        onChange={setActualQualitySpec}
                        type="text"
                        className={'input-class'}
                        placeholder="Actual Quality Spec"/>
                    </td>
                </tr>
            </tbody>
        </table>

        <table className='table-wrapper-style '>
            <thead>
                <tr>
                    <th className='table-style'>Volume (Packs)</th>
                    <th className='table-style'>Price (INR/{asinUom})</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='table-style'>
                        <Input
                            id="pack-size-input"
                            value={volume}
                            onChange={setVolume}
                            type="number"
                            className={'input-class'}
                            placeholder="Volume"/>
                    </td>
                    <td className='table-style'>
                        <Input
                            id="pack-size-input"
                            value={price}
                            onChange={setPrice}
                            type="number"
                            className={'input-class'}
                            placeholder="Price"/>
                    </td>
                </tr>
            </tbody>
        </table>

            <Modal
                title="Validation Error"
                open={openValidationErrorPrompt}
                aria-describedby="modal-description"
                scrollContainer="viewport"
                onClose={onValidationErrorClose}>
                <div className={"modal-content"}>
                    {validationErrorMessages}
                </div>

                <ModalFooter>
                    <Row alignmentHorizontal="end">
                        <Button type="primary" size="small" onClick={onClickButton}>
                            Dismiss
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>

            <Modal
                title="Saving Trade"
                open={openSaveTradePrompt}
                aria-describedby="modal-description"
                onClose={onSaveTradeClose}>
                <div className={"modal-content"}>
                    <text>Saving trade for: <text className="bold-font">{buyingAsinItem.title}</text></text>
                    <text>Modified Values: </text>
                    {saveTradeSummary}
                </div>
                <ModalFooter>
                    <Row alignmentHorizontal="end">
                        <Button type="secondary" onClick={onClickFooterButton}>
                            Cancel
                        </Button>
                        <Button type="primary" disabled={savingTrade} onClick={onClickSaveTradeButton}>
                            {saveBtnLabel}
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>

            <div className={"condition-statement-style"}>
                1. Config changes
            </div>
        <div className={'save-button-container'}>
            <Button
                id={'save-trade-btn'}
                className='save-button'
                    onClick={saveTradeHandler}
                    size="large"
                    type="primary">
                Save
            </Button>
        </div>
        </>
    )
}
