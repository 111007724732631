import {auth, getCognitoSession, retrieveUserInfo} from "src/authentication/AuthGenerator";
import axios from "axios";
import {getApiGatewayBaseURL} from "src/utils/apiUtils";
import {onSignOutClick} from "src/components/NavBar/Navbar";

export async function doPostCall(data: any, endpoint: string) {
        const authorization = auth.getSignInUserSession().getIdToken().getJwtToken();
        const response = await axios.request({
            method: 'POST',
            url: getApiGatewayBaseURL + endpoint,
            data: data,
            headers: {
                'Authorization': authorization
            }
        })
            .catch(error => {
                return error;
            });
        return response.data;
};

export async function doGetCall(endpoint: string) {
    const authorization = auth.getSignInUserSession().getIdToken().getJwtToken();
    const response = await axios.request({
        method: 'GET',
        url: getApiGatewayBaseURL + endpoint,
        headers: {
            'Authorization': authorization
        }
    })
        .catch(error => {
            return error;
        });
    return response.data;
};

export function isUserAuthorized() {
    let isAuthorized = false;
    const requestData = {
        'username': retrieveUserInfo(auth.getSignInUserSession().getIdToken().getJwtToken()),
        'clientName': 'FIELD_PRICE_WEBSITE'
    }
    doPostCall(requestData, "/isAuthorized").then((response) => {
        isAuthorized = response.isAuthorized;
        if(!isAuthorized) {
            onSignOutClick();
        }
    });
}
