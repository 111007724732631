import React from 'react';
import './apmc-selection-screen.scss';
import {APMC} from "./APMCSelection";
import {KatDropdown} from '@amzn/katal-react';

interface apmcProps {
    apmcList: APMC[],
    onApmcSelection: (value: string) => void;
}

export default function APMCDropdown({apmcList, onApmcSelection}: apmcProps){

    const apmcsOption = apmcList.map(apmc => ({"name": apmc.apmcName, "value": apmc.apmcName}))

    return (
        <div className={"apmc-dropdown-wrapper"}>
            <text className={"apmc-dropdown-label"}>APMC</text>
            <div className={"apmc-dropdown-menu"}>
                <KatDropdown
                    name = {"APMC Selection"}
                    options ={apmcsOption}
                    placeholder='Select APMC'
                    onChange={e=>onApmcSelection(e.detail.value)}
                />
            </div>
        </div>
    );
}
