import React from 'react';
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import homeTokens from "@amzn/meridian-tokens/base/icon/home"
import signOutTokens from "@amzn/meridian-tokens/base/icon/sign-out"
import './navbar.scss';
import { useNavigate } from "react-router-dom";
import { getStage } from "src/utils/apiUtils";
import {auth} from "src/authentication/AuthGenerator";
import {isUserAuthorized} from "src/utils/requests";
const authConfig = require('src/authentication/AuthConfig.json');

export const onSignOutClick = () => {
    const stage = getStage();
    const cognitoConfig = authConfig.auth_stages[stage]
    auth.clearCachedTokensScopes();
    localStorage.removeItem("ReloadCount");
    const {domain, redirectUriSignIn, responseType, clientId, state, tokenScopesArray, identityProvider, errorMessage}  = cognitoConfig;
    window.location.href = `https://${domain}/login?redirect_uri=${redirectUriSignIn}&response_type=${responseType}&client_id=${clientId}&identity_provider=Federate-OpenID&scope=${tokenScopesArray[0]}%20${tokenScopesArray[1]}`
}

export default function Navbar() {

    let navigate = useNavigate();
    const onHomeClick = () => {
        isUserAuthorized();
        navigate(`/`);
    }

    return (
        <div className={"navbar-wrapper"}>
            <div className={"home-icon-wrapper"}>
                <Button id={"home-icon"} type="icon" size="xlarge" onClick={()=>{onHomeClick()}}>
                    <Icon className={"home-icon"} tokens={homeTokens}/>
                </Button>
                <div className={"icon-label"}>
                    Home
                </div>
            </div>
            <div className={"sign-out-icon-wrapper"}>
                <Button id={"sign-out-icon"} type="icon" size="xlarge" onClick={()=>{onSignOutClick()}}>
                    <Icon className={"sign-out-icon"} tokens={signOutTokens}/>
                </Button>
                <div className={"icon-label"}>
                    Logout
                </div>
            </div>
        </div>
    )
}
